import React from "react";
import RoleComponent from "../Component/UserRoleComponent";
import RoleType from "../Component/RoleType";

export default function getChipElement(id){
    let role = {};
    switch (id) {
    case 10:
        role = RoleType.GUEST;
        break;
    case 20:
        role = RoleType.REPORTER;
        break;
    case 30:
        role = RoleType.DEVELOPER;
        break;
    case 40:
        role = RoleType.MAINTAINER;
        break;
    case 50:
        role = RoleType.OWNER;
        break;
    default:
    }
    if(Object.getOwnPropertyNames(role).length === 0){
        return null;
    }
    return <RoleComponent role={role}/> ;
}
