import axios from "axios";
import { rematchError } from '../requestErrors';
import config from "../../config";

const client = axios.create({
    baseURL: config.ENV_DEV,
    //Allowing the use of GitLab personal Token to get more info
    headers: (process.env.REACT_APP_TOKEN_GITLAB) ? {
        common: {
            'Private-Token': process.env.REACT_APP_TOKEN_GITLAB,
            'Authorization': 'Bearer ' + process.env.REACT_APP_TOKEN_GITLAB
        }
    } : {}
});

//TODO: Find way to use GraphQL API (GitLab 13.5) when available to get more useful info and get rid of useless information
export const getAllIssuesByGroup = (id = config.SIA_GROUP_ID, page = 1, per_page = 100) => {
    return client.get('/groups/' + id + '/issues', {
        params: {
            page: page,
            per_page: per_page,
            labels: "Accepting merge requests",
            state: "opened",
            confidential: false,
            order_by: "updated_at"
        }
    }).then(response => {
        return {
            data: response.data,
            pagination: {
                'next-page': response.headers["x-next-page"],
                'previous-page': response.headers["x-prev-page"],
                'page': response.headers["x-page"],
                'total': response.headers["x-total"],
                'total-page': response.headers["x-total-pages"],
            }
        };
    }).catch(rematchError);
};
